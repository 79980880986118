.daily-view-wrapper {
  height: 100%;

  &.dragging {
    cursor: grabbing !important;
  }
  .side-panel {
    min-width: 250px;
    width: 15% !important;
  }

  .draggable-container {
    height: 100%;
    width: 100%;
  }

  .main-panel {
    position: relative;
  }

  .empty-results {
    text-align: center;
    padding: 5rem;
    height: 15%;
  }
}

.daily-view-container {
  position: absolute;
  inset: 0;

  .header-wrapper {
    display: grid;
    grid-template-columns: 200px repeat(7, minmax(5%, 1fr));
    gap: 1px;
    background-color: #fff;

    .header-cell {
      padding: 10px;
      background-color: theme-color('header-end');
      text-align: center;
      height: auto;

      &.title {
        font-weight: bold;
      }

      &.link {
        font-weight: bold;
        color: blue;
        text-decoration: underline;
      }

      &.selected {
        background-color: theme-color('header-start');
        font-weight: bold;
      }

      .clickable {
        cursor: pointer;
        color: inherit;
        padding: 1rem;

        &:hover {
          font-weight: bold;
          color: $red;
        }
      }
    }
  }

  .focus-row {
    padding-bottom: 2px;

    .worker-row {
      border-top: 3px solid $orange;
      border-bottom: 3px solid $orange !important;
    }
  }

  .infinite-scroll-component__outerdiv {
    display: contents;
  }

  .content-wrapper {
    height: calc(100% - 40px) !important;
    overflow-y: auto;
    position: relative;

    [class*='-card'] {
      user-select: none;
      touch-action: none;

      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .spinner-wrapper {
      width: 200px + $daily-view-screen-width;
      display: grid;
      align-items: center;
      grid-template-columns: 200px auto;

      .spinner {
        display: grid !important;
        z-index: 2;
        opacity: 0.6;
        background-color: $weekly-planner-background-colour;
        grid-column: 2;
      }
    }

    .timeline-wrapper,
    .worker-row {
      background-color: $white;
      min-height: auto;
      height: auto;
      width: fit-content;
    }

    .timeline-wrapper {
      display: flex;
      position: sticky;
      top: 0px;
      z-index: 10;

      /* horrid compatibility for older (read inferior) browsers*/
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Old versions of Firefox */
      user-select: none;

      border-bottom: 1px solid $weekly-planner-background-colour;
      cursor: grab;

      &.dragging {
        cursor: grabbing;
      }
    }

    .timeline-header {
      flex: 0 0 auto;
      z-index: 2;
      position: sticky;
      left: 0px;
      width: 200px;
      min-height: 2rem;

      font-weight: bold;
      background-color: inherit;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .timeline-content {
      flex: 0 0 auto;

      background-color: inherit;
      display: grid;
      grid-template-columns: $daily-view-cell-span;

      .hour-marker {
        grid-row: 1;
        grid-column: span 12;
        background-color: inherit;
        border-left: 2px solid $weekly-planner-background-colour;

        display: flex;
        align-items: center;

        padding: 0.25rem;

        font-size: 0.75rem;

        color: #aaa;
      }
    }

    .time-marker {
      position: sticky;
      bottom: 0;
      width: 5px;
      background-color: $red;
      z-index: 10;
      pointer-events: none;
      height: 100vh;
    }

    .worker-row {
      display: flex;
      border-bottom: solid 2px $weekly-planner-background-colour;

      .worker-cell-parent {
        background-color: $weekly-planner-background-colour;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 7;
      }
      .worker-cell {
        filter: drop-shadow(0 0.3rem 0.5rem rgba(0, 0, 0, 0.15));
        background-color: $white;

        width: 200px;
        height: 100%;
        min-height: 60px;
        display: flex;
        justify-content: space-between;

        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-left: solid 1px #e0e0e0;
      }

      .daily-cell {
        flex: 0 0 auto;
        // Set the element span
        position: relative;
        bottom: 0px;
        height: 100%;
        min-height: 100px;

        // Set the children grid to match parent span
        display: grid;
        grid-template-columns: $daily-view-cell-span;

        &.is-dragging-origin {
          background-color: $lightgreen;
          z-index: 5;
        }

        [class*='-card'] {
          z-index: 1;
          width: 100%;
          &.conflict {
            border: 4px solid $warning;
          }

          // Any card placeholder, put green border around the ghost
          &.dragging {
            margin: 0;
            border: 2px solid green;
          }
        }
      }

      .availability {
        background-color: green;
        height: 2rem;
        opacity: 0.5;
      }

      .break-card,
      .travel-card {
        background-color: $white;
        opacity: 0.8;
        height: 75%;
        padding: 0.25rem;
        border: 1px solid $black;
        position: relative;
        z-index: 10 !important;

        &.travel-conflict {
          border: 4px solid $warning;
        }

        &.tea {
          background-color: $tea-break !important;
        }
      }

      .drag-placeholder {
        background-color: $lightgreen;
      }
    }
  }

  .tooltip-container {
    position: relative;
    display: inline-block;
    cursor: pointer;

    &::after {
      content: attr(data-tooltip);
      white-space: pre-line;
      background-color: $black;
      color: $white;
      padding: 5px;
      border-radius: 5px;
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      z-index: 10;
      width: max-content;
      max-width: 200px;
      text-align: center;
    }

    &:hover::after {
      visibility: visible;
      opacity: 1;
    }
  }
}
